import React, { useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import styles from "../styles/Banner.module.scss";
import axios from "axios";
import router from "next/router";
import { LoadingCircle } from "./LoadingCircle";
import _debounce from 'lodash/debounce';
import YouTube from "react-youtube";

const SearchList = ({items,loadingSearch,openSearch}:any) => {
    
  useEffect(() => {
  }, [items,loadingSearch]);

  const toDetailPage = (id:any) => {
      router.push("/job/" + id)
  }

  const ListItems = items.map((item: any, index: number) => (
      <li onClick={()=>{toDetailPage(item.id)}} key={index} className="hover:bg-gray h-[44px] p-4 flex items-center cursor-pointer
       text-sm font-gotham-book uppercase whitespace-nowrap">
          {item.name.length > 28 ? item.name.slice(0, 28) + "...." : item.name}</li>
  ));
  const NotFound = (
      <div className="mt-5 flex flex-col justify-center items-center">
        <Image
            src="/images/not_found.svg"
            alt="icon-search"
            className=""
            width={100}
            height={100}
        />
        <span className="mb-1 text-sm">Không tìm được kết quả phù hợp</span>
        <span className="text-gray-400 text-sm">Vui lòng tìm kiếm bằng từ khóa khác</span>
      </div>
  );
  const Loading = (
      <div className="mt-10 flex justify-center items-center">
        <LoadingCircle/>
      </div>
  );
  return (
      openSearch && <ul className="pt-2 pb-5 text-xl font-gotham-book list-none absolute overflow-auto
       bg-white rounded-b-2xl shadow-md overflow-y-scroll z-[2] h-[200px] w-[292px]">
        {loadingSearch ? Loading : (items.length > 0 ? ListItems : NotFound) }
      </ul>
  );
}

export const HeaderMobile = () =>{
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      loop: 1,
      playlist: "6bFLxuZusqk",
      modestbranding: 1,
      controls: 0,
      mute: 1,
      showinfo: 0,
      rel: 0,
    },
  };
  const [nameInput, setNameInput] = useState("");
  const [items, setItems] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openRadius, setOpenRadius] = useState(false);
  const wrapperRef = useRef(null);
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of NavbarMobile
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSearch(false);
          setOpenRadius(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const handleChangeNameInput = (event:any) => {
    if(event == '') {
      setOpenRadius(false);
    } else {
      setOpenRadius(true);
    }
    setNameInput(event);
    debounceDropDown(event)
  }

  const focusSearch = () => {
    setOpenSearch(true);
    if(nameInput) {
      setOpenRadius(true);
    }
  }

  const debounceDropDown = useCallback(_debounce((nextValue: any) => fetchRecruitment(nextValue), 100), [])

  const fetchRecruitment = (name:any) => {
    setOpenSearch(true);
    setLoadingSearch(true);
    setItems([]);
    axios.get(`${process.env.NEXT_PUBLIC_API_URL}/recruitment/all-office-jobs?name=${name.trim()}`).then(res => {
      setItems(res.data.data);
      setLoadingSearch(false);
    });
  }
  const video_banner_url = "https://edutalk-cdn.sgp1.cdn.digitaloceanspaces.com/video-banner-recruitment/banner-mobile-update.mp4";
  const handleVideoEnd = (event: any) => {
    event.target.seekTo(0);
    event.target.playVideo();
  };
  return (
    <>
                <div
                className="mb:hidden relative block bg-blue-violet h-[256px] pt-7"
            >
              <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
                  {/* <video autoPlay muted loop id="video-bg" style={{ width: "100%", height: "100%", objectFit: "cover" }}> */}
                      {/* <source src={video_banner_url} type="video/mp4" /> */}
                      {/* Your browser does not support the video tag. */}
                  {/* </video> */}
                <YouTube
                  videoId="6bFLxuZusqk"
                  opts={videoOptions}
                  onEnd={handleVideoEnd}
                  className="video-frame"
                  style={{
                    // position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
                  {/*<Image src="/images/banner.jpg" width="100" height="100" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />*/}
                        {/* <video playsInline autoPlay muted loop id="video-bg" style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                            <source src={video_banner_url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> */}
                    
              </div>
                <div className="relative flex flex-col items-center h-full z-10" style={{ paddingTop: "30%"}}>
                  <div ref={wrapperRef} onFocus={() => {focusSearch()}} className="mobile-search relative">
                    <input
                        className={[
                          "font-gotham-book text-gray-400 text-base w-[292px] h-[35px] border-none outline-none",
                          !openRadius ? 'rounded-[10px]' : 'rounded-t-[10px] rounded-b-[0]',
                          styles.banner__input,
                        ].join(" ")}
                        type="text"
                        placeholder="Search..."
                        name="name" value={nameInput} onChange={e => handleChangeNameInput(e.target.value)}
                    />
                    {openRadius ? <div className="border-b border-gray-300 border-solid"></div> : '' }
                    <div
                        className={[
                          "bg-[#AB90EA] w-[35px] h-[35px] flex justify-center items-center z-10 absolute",
                          styles.banner__search,
                        ].join(" ")}
                    >
                      <Image
                          src="/images/search.png"
                          alt="icon-search"
                          width={17.77}
                          height={17.97}
                      />
                    </div>
                    {nameInput ? <SearchList items={items} loadingSearch={loadingSearch} openSearch={openSearch}/> : ''}
                  </div>
                </div>
            </div>  
      </>
  );
}

export default HeaderMobile;


